import { TAddress } from '@updater/ui-widgets';

export enum PaymentCadenceKey {
  MONTHLY = 'eleven_pay',
  ANNUALLY = 'annual',
}

export interface Address extends TAddress {
  status?: string;
  selected?: boolean;
}

export interface Schedule {
  amount: number;
}

export interface CardSummary {
  lastFour: string;
  cardType: string;
}
export interface Quote {
  payment_schedule: { schedule: Schedule[]; total_amount: number };
  grandtotal: string;
}

export interface PaymentMethod {
  payment_method_id: string;
}
export interface PurchaseReceipt {
  agreement_id: string;
  status_code: string;
  policy_number: string;
  // empty details array returned from server
  details: any;
}

export interface SureFlow {
  construction_type: string | null;
  existingItemData: { [key: string]: any };
  requirements: Array<string>;
  dynamicForm: { [key: string]: any };
  previousScreen: string | null;
  animal_injury: string | null;
  dwelling_type: string | null;
  has_intrested_party: string | null;
  has_losses: string | null;
  has_mailing_address: string | null;
  has_sni: string | null;
  loss_date: string | null;
  policy_effective_date: string | null;
  intrested_party_address: { [key: string]: any };
  intrested_party_address_street: string | null;
  intrested_party_address_unit: string | null;
  intrested_party_address_city: string | null;
  intrested_party_address_state: string | null;
  intrested_party_address_postalCode: string | null;
  intrested_party_name: string | null;
  is_mobile_or_manufactured_home: string | null;
  mailing_address: { [key: string]: any };
  mandatory_insurance_requirement: string | null;
  mailing_address_street: string | null;
  mailing_address_unit: string | null;
  mailing_address_city: string | null;
  mailing_address_state: string | null;
  mailing_address_postalCode: string | null;
  number_of_losses: string | null;
  pni_first_name: string | null;
  pni_last_name: string | null;
  pni_email: string | null;
  pni_phone_number: string | null;
  policyPapersWrapper: string | null;
  sni_first_name: string | null;
  sni_last_name: string | null;
  policyAddressList: { [key: string]: Address };
  plan_id: string | null;
  quote_id: string | null;
  policy_number: string | null;
  agreement_id: string | null;
  status_code: string | null;
  payment_method_id: string | null;
  user_id: string | null;
  move_id: string | null;
  ratesResponse: { [key: string]: any };
  purchaseResponse: PurchaseReceipt | null;
  checkoutResponse: Quote[] | null;
  payment_cadence: PaymentCadenceKey;
  personal_property_coverage: number | null;
  all_peril_deductible: number | null;
  hurricane_deductible: number | null;
  liability_limit: number | null;
  medical_limit: number | null;
  include_replacement_cost: boolean | null;
  include_water_backup: boolean | null;
  include_identity_fraud: boolean | null;
  include_earthquake: boolean | null;
  screens: { [key: string]: string };
  // TODO: this needs to be transitioned to server state
  // and not persisted to localstorage as even lastFour is considered sensitive PII
  cardInfo: CardSummary | null;
  payment_date: string | null;
  error_page_copy: { title: string; subtitle: string } | null;
  collectInterestedPartyDetails: boolean;
}

export interface SureUserDataStore extends SureFlow {
  setUserData: (userData: Object) => void;
  resetUserData: () => void;
}

export interface Provider {
  cta_url: string;
  description: string;
  external_property_id: string;
  name: string;
  requirements: string[];
  required: boolean;
  logo?: string;
  exclude_sure?: boolean;
  logo_id?: string;
  note?: string;
}

export type PropertyAddress = {
  city?: string;
  postal?: string;
  unit?: string;
  region?: string;
  street_address?: string;
  country_code?: string;
};
